import { useTranslation } from 'react-i18next'

import { Button, ButtonVariant, Icon, IconName } from '~/tenset-components'
import { Namespace } from '~/i18n'
import useLocalizePathname from '~/i18n/use-localize-pathname'

interface ReadMoreButtonProps {
  link: string
  buttonVariant?: ButtonVariant
}

export default function ReadMoreButton({
  link,
  buttonVariant = ButtonVariant.Secondary,
}: ReadMoreButtonProps) {
  const { t } = useTranslation(Namespace.COMMON)
  const localizePathname = useLocalizePathname()

  const { ChevronRight16 } = IconName

  return (
    <Button
      to={localizePathname(link)}
      variant={buttonVariant}
      className="text-center"
    >
      {t('common:read-more')}
      <Icon name={ChevronRight16} color="currentColor" />
    </Button>
  )
}
