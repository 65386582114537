import { useTranslation } from 'react-i18next'

import { Namespace } from '~/i18n'
import { Button, ButtonVariant } from '~/tenset-components'
import urls from '~/utils/urls'

interface LaunchYourProjectButtonProps {
  variant?: ButtonVariant
}

export function LaunchYourProjectButton({
  variant = ButtonVariant.Primary,
}: LaunchYourProjectButtonProps) {
  const { t } = useTranslation(Namespace.COMMON)
  const { launchYourProjectLink } = urls

  return (
    <Button to={launchYourProjectLink} variant={variant}>
      {t('common:launch-your-project')}
    </Button>
  )
}
